import React, {useEffect, useState} from 'react';
import OrganizationChart from "@dabeng/react-orgchart";

const AnnieOrgChart = () => {
    const [tree, updateTree] = useState();
    useEffect(()=> {
        const getData = async() => {
            const response = await fetch("/organization-chart", {method: "GET"});
            const json = await response.json();
            console.log("Got JSON: " + JSON.stringify(json));
            updateTree(parseToTree(json));
        }
        getData();
    }, []);

    return tree === undefined ? null :
    <OrganizationChart datasource={tree} pan={true} zoom={true}/>;
}

function parseToTree(json) {
    const departments = json["organizations"]["departments"];

    const departmentNodes = [];

    const tree = {
        id: "root",
        name: "",
        title: "Organization",
        children: departmentNodes
    };

    for (const department of departments) {
        const departmentName = department["name"];
        const manager = department["managerName"];

        const employees = department["employees"];
        const children = [];

        departmentNodes.push({
            id: departmentName,
            name: manager,
            title: departmentName + " Department Manager",
            children: children
        });

        for (const employee of employees) {
            const employeeName = employee["name"];
            children.push({
                id: employeeName,
                name: employeeName,
                title: departmentName + " Employee",
                children: []
            });
        }
    }

    console.log("Tree: " + JSON.stringify(tree));
    return tree;
}

export default AnnieOrgChart;